import { Grid } from "@mui/material";
import LAYOUT from "components/Constants";
import styled from "styled-components";


export interface StylePersonalizado {
  borderRadius?: string;
  padding?: string;
  fontWeight?: string;
  importFont?: string;
  fontFamily?: string;
  btnSite?: string;

  colors?: {
    main?: string;
    secondary?: string;
    menuMobile?: string;
    footerPrimary?: string;
    footerSecundary?: string;
  };
}
export const GridFooter = styled(Grid)<StylePersonalizado>`
  background-color: ${(props) => props.colors?.footerPrimary};

`;

export const InputNewsLatter = styled.input`
  width: 100%;
  margin: 0 auto;
  background-color: #c5ebe9;
  border: none!important;
  padding: 10px 10px;
  text-align: left;
  color: #42a7a2!important;
  font-family: ${LAYOUT.fonts.family.descricao};
  font-size: ${LAYOUT.fonts.fontSize.descricao.default};
  @media only screen and (min-width: 0px) {
    font-size: 12px!important;
  }
  @media only screen and (min-width: 600px) {
    font-size: 12px!important;
  }
  @media only screen and (min-width: 900px) {
    font-size: 12px!important;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 12px!important;
  }
  @media only screen and (min-width: 1536px) {
    font-size: 14px!important;
  }
  ::placeholder{
    color: #42a7a2!important;
  }

`;
