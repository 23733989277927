import { useState } from "react";
import { GridForm, GridHeader, GridWhatsapp, IconeWhatsapp } from "./style";
import { Backdrop, Fade, Grid, IconButton, Modal } from "@mui/material";
import * as yup from "yup";
import Descricao from "components/TextoSite/Descricao";
import { useFormik } from "formik";
import axios from "axios";
import FormikForm from "components/FormikForm";
import InputFormik from "components/FormikForm/InputFormik";
import ButtonDourado from "components/Botoes/ButtonDourado";
import ReCAPTCHA from "react-google-recaptcha";
import { useRouter } from "next/router";
import { IconSquareX } from "@tabler/icons-react";
import LAYOUT from "components/Constants";

const validationSchema = yup.object({
  nome: yup.string().required("Obrigatorio!"),
  sobreNome: yup.string().required("Obrigatorio!"),
  email: yup.string().email("Email invalido!").required("Obrigatorio!"),
  telefone: yup.string().required("Obrigatorio!"),
  recaptcha: yup.string().required("Campo obrigatorio"),
});

interface FormValues {
  nome: string;
  sobreNome: string;
  email: string;
  telefone: string;
  recaptcha: string;
}

export default function WhatsappContato() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const handleRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };
  const router = useRouter();
  const handleClose = () => setOpen(false);
  const formik = useFormik<FormValues>({
    initialValues: {
      nome: "",
      sobreNome: "",
      email: "",
      telefone: "",
      recaptcha: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // setOpenModal(true);
      const enviaForm = await axios.post("/api/contato-whatsapp", values);
      window.open(
        "https://api.whatsapp.com/send?phone=551238943000",
        "_blank",
        "noopener,noreferrer"
      );
      //   setOpenModal(false);
      //   setOpenModalSucesso(true);
      resetForm();
    },
  });
  return (
    <GridWhatsapp>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ border: "none" }}
        disableScrollLock
      >
        <Fade in={open}>
          <GridForm>
            <IconButton
              sx={{ position: "absolute", top: "1px", right: "1px" }}
              onClick={handleClose}
            >
              <IconSquareX size={"20px"} color={LAYOUT.colors.cinza} />
            </IconButton>
            <GridHeader>
              <div>
                <img
                  src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/whatsapp-form.png`}
                  alt=""
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Descricao
                  sx={{
                    fontSize: "12px",
                    textAlign: "center",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  Precisa de ajuda?
                </Descricao>
                <Descricao
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  Atendimento Whatsapp
                </Descricao>
              </div>
            </GridHeader>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Descricao
                sx={{
                  fontSize: "11px",
                  textAlign: "center",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                Segunda a Sábado - 9h as 17h
              </Descricao>
              <Descricao
                sx={{
                  fontSize: "11px",
                  textAlign: "center",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                Domingo - 10h as 19h
              </Descricao>
              <Descricao
                sx={{
                  fontSize: "11px",
                  textAlign: "center",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                12 3894-3000
              </Descricao>
              <Descricao
                sx={{
                  fontSize: "11px",
                  textAlign: "center",
                  padding: "0px",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                Somente hospedagem
              </Descricao>
              <div
                style={{
                  width: "100%",
                  padding: "10px",
                }}
              >
                <FormikForm formik={formik}>
                  <Grid
                    width={"100%"}
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      <InputFormik
                        type="text"
                        placeholder="Nome"
                        name="nome"
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.nome && formik.touched.nome && (
                        <div
                          style={{
                            paddingLeft: "10px",
                            color: "#f96666",
                            fontFamily: "sans-serif",
                            fontSize: "10px",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.nome}
                        </div>
                      )}
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      marginTop={"10px"}
                    >
                      <InputFormik
                        type="text"
                        placeholder="Sobrenome"
                        name="sobreNome"
                        value={formik.values.sobreNome}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.sobreNome && formik.touched.sobreNome && (
                        <div
                          style={{
                            paddingLeft: "10px",
                            color: "#f96666",
                            fontFamily: "sans-serif",
                            fontSize: "10px",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.sobreNome}
                        </div>
                      )}
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      marginTop={"10px"}
                    >
                      <InputFormik
                        type="text"
                        placeholder="E-mail"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <div
                          style={{
                            paddingLeft: "10px",
                            color: "#f96666",
                            fontFamily: "sans-serif",
                            fontSize: "10px",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.email}
                        </div>
                      )}
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      marginTop={"10px"}
                    >
                      <InputFormik
                        type="text"
                        placeholder="Telefone"
                        name="telefone"
                        value={formik.values.telefone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.telefone && formik.touched.telefone && (
                        <div
                          style={{
                            paddingLeft: "10px",
                            color: "#f96666",
                            fontFamily: "sans-serif",
                            fontSize: "10px",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.telefone}
                        </div>
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      justifyContent={"center"}
                      marginTop={"6px"}
                    >
                      <ReCAPTCHA
                        style={{
                          margin: "0 auto",
                          transform: "scale(0.9)",
                        }}
                        size="normal"
                        sitekey={`${process.env.NEXT_PUBLIC_KEY_RECAPTCHA}`}
                        onChange={(value) => {
                          formik.setFieldValue("recaptcha", value);
                          handleRecaptchaChange(value);
                        }}
                      />
                      {formik.errors.recaptcha && formik.touched.recaptcha && (
                        <div
                          style={{
                            paddingLeft: "10px",
                            color: "#f96666",
                            fontFamily: "sans-serif",
                          }}
                        >
                          {formik.errors.recaptcha}
                        </div>
                      )}
                    </Grid>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      marginTop={"10px"}
                    >
                      <ButtonDourado type="submit" padding="10px 40px">
                        Entrar
                      </ButtonDourado>
                    </Grid>
                  </Grid>
                </FormikForm>
              </div>
            </div>
          </GridForm>
        </Fade>
      </Modal>

      <IconeWhatsapp
        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/whatsapp-form.png`}
        alt=""
        onClick={handleOpen}
      />
    </GridWhatsapp>
  );
}
