import { Button } from "@mui/material";
import { ReactNode } from "react";

interface ButtonRoxoProps {
  largura?: string;
  altura?: string;
  link?: boolean;
  url?: string;
  onClick?: () => void;
  children: ReactNode;
  type?: "submit" | "button";
}

export default function ButtonRoxo({
  largura,
  altura,
  type = "button",
  link = false,
  url="",
  onClick,
  children,
}: ButtonRoxoProps) {
  if (!link) {
    return (
      <button
        type={type}
        className="btnRoxo"
        onClick={onClick}
        style={{ width: largura, height: altura }}
      >
        {children}
      </button>
      // <Button variant="contained" color="secondary" className="btnLaranja" onClick={props.onClick}>Reservar</Button>
    );
  }else{
    return (
        <a
          className="btnRoxo"
          href={url}
          style={{ width: largura, height: altura, textDecoration: "none", textAlign: "center" }}
        >
          {children}
        </a>
        // <Button variant="contained" color="secondary" className="btnLaranja" onClick={props.onClick}>Reservar</Button>
      );
  }
}
