/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable react/jsx-no-target-blank */
import { Grid, useMediaQuery } from "@mui/material";
import { Texto, Titulo } from "./style";
import Descricao from "components/TextoSite/Descricao";
import TituloDourado from "components/TextoSite/TituloDourado";
import NewsLetter from "../NewsLetter";

export default function Contato() {
  const larguraTelaFooter = useMediaQuery("(min-width:589px)");
  return (
    <Grid
      container
      direction="column"
      marginBottom={"10px"}
      sx={{ display: "flex", flex: 1 }}
      width={"auto"}
    >
      <Grid
        container
        direction={"column"}
        className="mobileMenu"
        sx={{ margin: "0px 0px" }}
      >
        <a href="/como-chegar" style={{ margin: "5px 0px" }}>
          <Descricao>COMO CHEGAR</Descricao>
        </a>
        <a
          href={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/infos/esg.pdf`}
          style={{ margin: "5px 0px" }}
        >
          <Descricao>PROGRAMA ESG</Descricao>
        </a>
        <a href="/historia-pessoal" style={{ margin: "5px 0px" }}>
          <Descricao>HISTÓRIA PESSOAL</Descricao>
        </a>
        <a href="/fale-conosco" style={{ margin: "5px 0px" }}>
          <Descricao>FALE CONOSCO</Descricao>
        </a>
        <a href="/trabalhe-conosco" style={{ margin: "5px 0px" }}>
          <Descricao>TRABALHE CONOSCO</Descricao>
        </a>
      </Grid>
      <Descricao sx={{ margin: "5px 0px 10px 0px" }}>
        CONTATO CENTRAL DE RESERVAS
      </Descricao>
      <a
        href="tel:+551238943000"
        target="_blank"
        style={{
          color: "#fff",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Descricao>Telefone/WhatsApp: +55 12 3894 3000</Descricao>
      </a>
      <Descricao
        size="12px"
        margin="0px"
        marginTop="0px"
        padding="0px"
        color="#fff"
      >
        <a
          href="mailto:reservas@dpnybeach.com.br"
          target="_blank"
          style={{
            color: "#fff",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Descricao>E-mail: reservas@dpnybeach.com.br</Descricao>
        </a>
      </Descricao>
      <a
        style={{ textAlign: "left", display: "flex", flexDirection: "column" }}
        href="https://maps.google.com/?q=Av+Jos%C3%A9+Pacheco+do+Nascimento,+7668,+Praia+do+Curral,+Ilhabela,+SP,+Brasil,+11641-000"
        target="_blank"
      >
        <Descricao>Endereço: Av José Pacheco do Nascimento, 7668</Descricao>
        <Descricao>
          Praia do Curral | Ilhabela | SP | Brasil | 11641-000
        </Descricao>
      </a>
      <div className="newslatterDesktop">
        <NewsLetter />
      </div>
    </Grid>
  );
}
