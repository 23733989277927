import { TextField } from "@mui/material";
import LAYOUT from "components/Constants";
import styled from "styled-components";

export const GridWhatsapp = styled.div`
  position: fixed;
  bottom: 80px;
  right: 23px;
  z-index: 999;
  @media (max-width: 768px) {
    bottom: 60px;
    right: 10px;
  }
`;

export const GridForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: fixed;
  border: none;
  bottom: 85px;
  right: 85px;
  z-index: 9999;
  width: 300px;
  height: 510px;
  background-color: #f1f1f1;
  box-shadow: 1px 1px 7px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: none;
  @media (max-width: 768px) {
    bottom: 66px;
    right: 66px;
  }
`;

export const GridHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  height: 60px;
  border-radius: 5px 5px 0px 0px;
`;

export const IconeWhatsapp = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.04);
  }
`;
