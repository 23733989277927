import { Grid } from "@mui/material";
import LAYOUT from "components/Constants";
import styled from "styled-components";

export interface StylePersonalizado {
  borderRadius?: string;
  padding?: string;
  fontWeight?: string;
  importFont?: string;
  fontFamily?: string;
  btnSite?: string;

  colors?: {
    main?: string;
    secondary?: string;
    menuMobile?: string;
    footerPrimary?: string;
    footerSecundary?: string;
  };
}

export const BlocoSite = styled.div`
  display: ${LAYOUT.containers.blocoSite.display};
  flex-direction: ${LAYOUT.containers.blocoSite.flexDirection};
  justify-content: ${LAYOUT.containers.blocoSite.justifyContent};
  padding: ${LAYOUT.containers.blocoSite.padding.default};
  @media only screen and (min-width: 0px) {
    ${LAYOUT.containers.blocoSite.margin.xs};

  }
  @media only screen and (min-width: 0px) {
    margin: ${LAYOUT.containers.blocoSite.margin.xs};

  }
  @media only screen and (min-width: 600px) {
    margin: ${LAYOUT.containers.blocoSite.margin.sm};

  }
  @media only screen and (min-width: 900px) {
    margin: ${LAYOUT.containers.blocoSite.margin.md};

  }
  @media only screen and (min-width: 1200px) {
    margin: ${LAYOUT.containers.blocoSite.margin.lg};

  }
  @media only screen and (min-width: 1536px) {
    margin: ${LAYOUT.containers.blocoSite.margin.xl};

  }
`;

export const ContentSite = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
`;

export const divRelative = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;



export const divLogoSite = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  text-align: center;
  transition: all ease 0.8s;
`;

export const divMenuContent = styled.div`
  display: flex;
  position: fixed;
  top: 120px;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #d8f4f3d9;
  height: 50px;
  transition: all ease 1s;
  z-index: 999;
  @media only screen and (max-width: 1100px) {
    background-color: inherit;
    width: 100%;
    height: 40px;
    right: 0px;
    top: 10px;
    justify-content: end;
    align-items: center;
    text-align: center;
  }
`;

export const divMenuFixo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
`;

export const divMenuBloco = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 1100px) {
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    padding: 10px;
  }
`;

export const divMenu = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: solid 2px #fff;
  background-color: #d8f4f363;
  z-index: 999;
  text-align: left;
  margin-right: 10px;
  transition: all ease 0.3s;
  @media only screen and (max-width: 1100px) {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: solid 2px #fff;
    background-color: #d8f4f363;
  }
`;

export const divMenuFixoInterno = styled.div`
  display: flex;
  min-height: 35px;
  height: auto;
  text-align: left;
  margin-right: 10px;
  transition: all ease 0.3s;
  justify-content: center;
  align-items: center;
`;

export const hamburgMenu = styled.div`
  display: inherit;
  color: #fff;
  cursor: pointer;
  @media only screen and (max-width: 1100px) {
    display: inherit;
  }
`;

export const menuMobile = styled.div<StylePersonalizado>`
  display: none;
  position: fixed;
  width: 300px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  justify-content: flex-start !important;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  z-index: 999;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    @keyframes scale-display {
      0% {
        opacity: 0;
        width: 0px;
        /* transform: scale(0);
    -webkit-transform: scale(0); */
      }

      100% {
        opacity: 1;
        width: 100%;
        /* transform: scale(1);
    -webkit-transform: scale(1); */
      }
    }
    @keyframes scale-display--reversed {
      0% {
        width: 100%;
      }
      100% {
        width: 0px;
      }
    }
  }
  &.transition {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: scale-display 0.3s;
  }
  &.out {
    animation: scale-display--reversed 0.5s;
  }
  @media only screen and (min-width: 1101px) {
    @keyframes scale-display {
      0% {
        opacity: 0;
        width: 0px;
        /* transform: scale(0);
    -webkit-transform: scale(0); */
      }

      100% {
        opacity: 1;
        width: 300px;
        /* transform: scale(1);
    -webkit-transform: scale(1); */
      }
    }
    @keyframes scale-display--reversed {
      0% {
        width: 300px;
      }
      100% {
        width: 0px;
      }
    }
  }
`;

export const closeMenuMobile = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  left: 248px;
  top: 10px;
  border-radius: 10px;
  border: solid 2px #000;
  background-color: #fff;
  justify-content: center;
  text-align: center;
  @media only screen and (max-width: 1100px) {
    right: 10px;
    left: auto;
  }
`;

export const divCalendario = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background-color: #d8f4f3;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const GridFooter = styled(Grid)<StylePersonalizado>`
  background-color: ${(props) => props.colors?.footerPrimary};
  background: url("/img/footer/bg-capitone-branco.jpg");

`;
export const GridFooterEndereco = styled(Grid)<StylePersonalizado>`
  background-color: ${(props) => props.colors?.footerSecundary};
`;
export const Titulo = styled.h1`
  font-size: 28px !important;
  font-weight: 400 !important;
  margin-bottom: 10px;
  color: #5c5c5c !important;
  @media (max-width: 540px) {
    font-size: 18px !important;
  }
`;

export const SubTitulo = styled.h2`
  font-size: 22px !important;
  font-weight: 400 !important;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #5c5c5c !important;
  @media (max-width: 540px) {
    font-size: 18px !important;
  }
`;

export const Texto = styled.p`
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: justify;
  color: #5c5c5c !important;
  a {
    text-decoration: none;
    color: #5c5c5c !important;
  }
  @media (max-width: 540px) {
    font-size: 14px !important;
  }
`;

export const ChamadaTexto = styled.p`
  font-size: 16px !important;
  font-weight: 800 !important;
  margin-top: 10px;
  margin-left: 10px;
  color: #5c5c5c !important;
  ::after {
    transform: rotate(45deg);
    background-color: #5c5c5c;
    content: " ";
    display: flex;
    position: absolute;
    height: 0.5rem;
    width: 0.5rem;
    margin-top: -12px;
    margin-left: -10px;
  }
  @media (max-width: 540px) {
    font-size: 14px !important;
  }
  `;
