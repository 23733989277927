export function validaData(data: string | null) {
    if(data == null){
        return false;
    }
    try {
        const parsed = JSON.parse(data);
        // Verifica se é um array de exatamente 2 itens
        if (!Array.isArray(parsed) || parsed.length !== 2) {
          return false;
        }
    
        // Verifica se ambos são números válidos e representam datas reais
        return parsed.every((timestamp) => {
          const date = new Date(timestamp);
          return typeof timestamp === "number" && !isNaN(date.getTime());
        });
      } catch (error) {
        return false; // Se JSON.parse falhar, não é um array válido
      }
}
