/* eslint-disable react/no-children-prop */
import { Modal, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { GridMobile, TituloCalendario, TituloPacotes } from "./styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useRouter } from "next/router";
import ButtonRoxo from "components/Botoes/ButtonRoxo";
import { useSiteContexto } from "context/context";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO, format } from "date-fns";
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import Calendario from "components/Calendario";
import TituloDourado from "components/TextoSite/TituloDourado";
import Descricao from "components/TextoSite/Descricao";
import Grid from "@mui/material/Grid2";
import Cookies from "js-cookie";
import { validaData } from "funcoes/validaData";
import LAYOUT from "components/Constants";

export default function FooterCalendarioRanger() {
  const larguraTela = useMediaQuery("(min-width:740px)");
  const router = useRouter();
  const [values, setValues] = useState<DateObject[]>([
    new DateObject(),
    new DateObject().add(1, "days"),
  ]);

  const [calendario, setCalendario] = useState(false);
  const {
    restricoes,
    menu,
    openMenu,
    handleOpenMenu,
    handleCloseMenu,
  }: {
    restricoes: any;
    menu: any;
    openMenu: boolean;
    handleOpenMenu: () => void;
    handleCloseMenu: () => void;
  } = useSiteContexto();

  useEffect(() => {
    try {
      const storedData = localStorage.getItem("date_searsh");

      if (storedData) {
        const dateStorage = JSON.parse(storedData) as DateObject[];

        if (validaData(storedData)) {
          setValues(dateStorage);
        }
      }
    } catch (error) {
      localStorage.removeItem("date_searsh");
    }
  }, []);

  const handleOpen = () => {
    handleOpenMenu();
  };
  const handleClose = () => {
    handleCloseMenu();
  };
  const [startDataCheckin, setStartDataCheckin] = useState(new Date());
  const [startDataCheckout, setStartDataCheckout] = useState(new Date());
  const [minDataCheckin, setMinDataCheckin] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [minDataCheckout, setMinDataCheckout] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  const handleCalendar = (ranges: DateObject[]) => {
    setValues(ranges);
    if (ranges.length > 1) {
      handleClose();
    }
    // if (ranges[0].selection.startDate && ranges.selection.endDate) {
    //   setShowCalendar(false); // Fecha o calendário após a segunda data
    // }
  };

  function pesquisar() {
    // router.push(`/diarias?checkin=${startDataCheckin}&checkout=${startDataCheckout}`);
  }

  const abreCalendario = () => {
    setCalendario(true);
  };

  const fechaCalendario = () => {
    setCalendario(false);
  };

  const isDisabledCheckin = (date: Date) => {
    return restricoes.checkin.some(
      (dataCheckin: any) =>
        date.getFullYear() === new Date(dataCheckin).getFullYear() &&
        date.getMonth() === new Date(dataCheckin).getMonth() &&
        date.getDate() === new Date(dataCheckin).getDate()
    );
  };

  const isDisabledCheckout = (date: Date) => {
    return restricoes.checkout.some(
      (dataCheckout: any) =>
        date.getFullYear() === new Date(dataCheckout).getFullYear() &&
        date.getMonth() === new Date(dataCheckout).getMonth() &&
        date.getDate() === new Date(dataCheckout).getDate()
    );
  };

  const [menuMobile, setMenuMobile] = useState(false);
  const [openPacotes, setOpenPacotes] = useState(false);
  const Componente = <div className="teste"></div>;
  return (
    <Grid
      container
      position={"fixed"}
      width={"100%"}
      height="auto"
      sx={{
        bottom: "0px",
        backgroundColor: "#00c4b8",
        zIndex: "9999",
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {larguraTela ? (
        <Grid
          container
          direction="row"
          maxWidth={"1200px"}
          padding="10px 5px"
          justifyContent="center"
          alignItems="center"
          position={"relative"}
          size={{
            xs: 12,
            sm: 12,
            md: 11,
            lg: 10,
            xl: 8,
          }}
        >
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            position={"relative"}
            size={{
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            }}
          >
            <Modal
              open={openMenu}
              onClose={handleClose}
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              disableScrollLock
              hideBackdrop={false}
              sx={{
                display: "flex",
                p: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                container
                position={"absolute"}
                bottom={"80px"}
                minWidth={"600px"}
                maxWidth={"600px"}
              >
                <Calendario
                  value={values}
                  onChange={handleCalendar}
                  disableCheckin={restricoes.checkin}
                  disableCheckout={restricoes.checkout}
                />
              </Grid>
            </Modal>
            <Grid
              container
              width={"100%"}
              direction={"row"}
              justifyContent={"center"}
              sx={{ cursor: "pointer", flexFlow: "nowrap" }}
            >
              <Grid
                container
                flexWrap={"nowrap"}
                direction="column"
                width={"220px"}
                justifyContent="center"
                alignItems={"start"}
              >
                <TituloDourado sx={{ fontSize: "16px", color: "#fff" }}>
                  RESERVE SUA ESTADA
                </TituloDourado>
              </Grid>
              <Grid
                container
                direction="column"
                width={"180px"}
                justifyContent="center"
                alignItems={"start"}
                marginLeft={"20px"}
                onClick={handleOpen}
              >
                <TituloCalendario>CHECK-IN</TituloCalendario>
                <Descricao sx={{ color: "#fff", margin: "0px 0px 0px 0px", padding: "0px", textAlign: "left", borderBottom: "1px solid #fff" }}>
                  {new DateObject(values[0]).toDate().toLocaleDateString()}
                  <KeyboardArrowDownIcon sx={{marginLeft: "5px"}} />
                </Descricao>
              </Grid>
              <Grid
                container
                direction="column"
                width={"160px"}
                justifyContent="center"
                alignItems={"start"}
                marginRight={"20px"}
                onClick={handleOpen}
              >
                <TituloCalendario>CHECK-OUT</TituloCalendario>
                <Descricao sx={{ color: "#fff", margin: "0px 0px 0px 0px", padding: "0px", textAlign: "left", borderBottom: "1px solid #fff" }}>
                  {new DateObject(values[1])?.toDate().toLocaleDateString() ==
                  null
                    ? new DateObject(values[0])?.toDate().toLocaleDateString()
                    : new DateObject(values[1])?.toDate().toLocaleDateString()}
                    <KeyboardArrowDownIcon sx={{marginLeft: "5px"}} />
                </Descricao>
              </Grid>
              <Grid
                container
                flexWrap={"nowrap"}
                alignItems={"center"}
                maxWidth={"400px"}
              >
                <a
                  className="btnLaranja"
                  style={{
                    width: "150px",
                    height: "40px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    localStorage.setItem("date_searsh", JSON.stringify(values));
                  }}
                  href={`/diarias?checkin=${format(
                    new Date(new DateObject(values[0])?.toDate()),
                    "yyyy-MM-dd"
                  )}&checkout=${format(
                    new Date(
                      values[1]
                        ? new DateObject(values[1])?.toDate()
                        : new DateObject(values[0])?.toDate()
                    ),
                    "yyyy-MM-dd"
                  )}`}
                >
                  Reservar
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            padding="10px 5px"
            onClick={() => {
              if (openMenu) {
                handleCloseMenu();
              } else {
                handleOpenMenu();
              }
            }}
            sx={{ cursor: "pointer", zIndex: "8" }}
            size={{
              sm: 7,
              md: 6,
            }}
          >
            <TituloDourado sx={{ color: "#fff", fontSize: "14px" }}>
              RESERVE SUA ESTADA
            </TituloDourado>
            <span
              style={{
                background: "none",
                textAlign: "right",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!openMenu ? (
                <KeyboardArrowUpIcon
                  sx={{
                    fontSize: "30px",
                    color: "#fff",
                    padding: "0px",
                    margin: "0px",
                  }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: "30px",
                    color: "#fff",
                    padding: "0px",
                    margin: "0px",
                  }}
                />
              )}
            </span>
          </Grid>
          <GridMobile
            container
            direction="column"
            zIndex={99999}
            display={openMenu ? "block" : "none"}
            item
            xs={12}
            sm={12}
            md={12}
            lg={8}
            xl={6}
            justifyContent="center"
            alignItems="center"
          >
            <Calendario
              value={values}
              onChange={setValues}
              disableCheckin={restricoes.checkin}
              disableCheckout={restricoes.checkout}
            />
            <Grid container direction={"row"} justifyContent={"space-around"} padding={"0px 15px"}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                width={"110px"}
                alignItems={"start"}
                marginTop={"10px"}
              >
                <TituloCalendario>CHECK-IN</TituloCalendario>
                <Descricao  sx={{ color: "#fff", margin: "0px 0px 0px 0px", padding: "0px", textAlign: "left", borderBottom: "1px solid #fff" }}>
                  {new DateObject(values[0]).toDate().toLocaleDateString()}
                  <KeyboardArrowDownIcon sx={{marginLeft: "5px"}} />
                </Descricao>
              </Grid>
              <Grid
                container
                direction="column"
                width={"110px"}
                justifyContent="center"
                alignItems={"start"}
                marginTop={"10px"}
              >
                <TituloCalendario>CHECK-OUT</TituloCalendario>
                <Descricao  sx={{ color: "#fff", margin: "0px 0px 0px 0px", padding: "0px", textAlign: "left", borderBottom: "1px solid #fff" }}>
                  {new DateObject(values[1])?.toDate().toLocaleDateString() ==
                  null
                    ? new DateObject(values[0])?.toDate().toLocaleDateString()
                    : new DateObject(values[1])?.toDate().toLocaleDateString()}
                    <KeyboardArrowDownIcon sx={{marginLeft: "5px"}} />
                </Descricao>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              marginTop={"10px"}
              marginBottom={"10px"}
              size={{
                sm: 12,
                md: 12,
                lg: 2,
              }}
            >
              <a
                className="btnLaranja"
                style={{
                  width: "150px",
                  height: "40px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                onClick={() => {
                  localStorage.setItem("date_searsh", JSON.stringify(values));
                }}
                href={`/diarias?checkin=${format(
                  new Date(new DateObject(values[0])?.toDate()),
                  "yyyy-MM-dd"
                )}&checkout=${format(
                  new Date(
                    values[1]
                      ? new DateObject(values[1])?.toDate()
                      : new DateObject(values[0])?.toDate()
                  ),
                  "yyyy-MM-dd"
                )}`}
              >
                Reservar
              </a>
            </Grid>
            <Grid
               container
               justifyContent="center"
               alignItems="center"
               height="30px"
               marginBottom={"5px"}
               size={{
                 sm: 12,
                 md: 12,
                 lg: 12,
               }}
             >
               <TituloPacotes>ou</TituloPacotes>
             </Grid>
             <Grid
               container
               justifyContent="center"
               marginBottom={"10px"}
               size={{
                 sm: 12,
                 md: 12,
                 lg: 12,
               }}
             >
               <ButtonRoxo
                 largura={"180px"}
                 altura={"40px"}
                 link={true}
                 url="/pacotes"
               >
                 Pacotes DPNY
               </ButtonRoxo>
             </Grid>
          </GridMobile>
        </>
      )}
    </Grid>
  );
}
