import { useMediaQuery } from "@mui/material";
import {
  IconCircleArrowLeftFilled,
  IconCircleArrowRightFilled,
} from "@tabler/icons-react";
import LAYOUT from "components/Constants";
import { Calendar, DateObject } from "react-multi-date-picker";

interface CalendarioProps {
  value: DateObject[];
  range?: boolean;
  disableCheckin?: Date[];
  disableCheckout?: Date[];
  onChange: (selectedDates: DateObject[]) => void;
}

export default function Calendario({
  value,
  range = true,
  onChange,
  disableCheckin,
  disableCheckout
}: CalendarioProps) {
  const isSmallScreen = useMediaQuery("(max-width: 425px)");
  const weekDays = ["D", "S", "T", "Q", "Q", "S", "S"];
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  return (
    <div
      className="custom-calendar-container"
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <Calendar
        numberOfMonths={isSmallScreen ? 1 : 2}
        range={range}
        value={value}
        onChange={onChange}
        weekDays={weekDays}
        className="CalendarioRange"
        months={months}
        shadow={false}
        minDate={new Date()}
        mapDays={({ date }) => {
          let props = {} as any;
          let isWeekend = [0].includes(date.weekDay.index);
          if (
            value.length == 0 || value.length > 1 &&
            disableCheckin?.some(
              (d: Date) =>
                date.toDate().getFullYear() === new Date(d).getFullYear() &&
                date.toDate().getMonth() === new Date(d).getMonth() &&
                date.toDate().getDate() === new Date(d).getDate()
            )
          ) {
            props.disabled = true;
            props.className = "dataDesativada";
          }
          if (
            value.length == 1 &&
            disableCheckout?.some(
              (d: Date) =>
                date.toDate().getFullYear() === new Date(d).getFullYear() &&
                date.toDate().getMonth() === new Date(d).getMonth() &&
                date.toDate().getDate() === new Date(d).getDate()
            )
          ) {
            props.disabled = true;
            props.className = "dataDesativada";
          }

          if (isWeekend) props.className = "diaCalendarioDourado";

          return props;
        }}
        renderButton={(direction: any, handleClick: any) => (
          <button
            onClick={handleClick}
            style={{ border: "none", background: "#fff" }}
          >
            {direction === "right" ? (
              <IconCircleArrowRightFilled
                colorRendering={"#fff"}
                color={LAYOUT.colors.dourado}
              />
            ) : (
              <IconCircleArrowLeftFilled
                colorRendering={"#fff"}
                color={LAYOUT.colors.dourado}
              />
            )}
          </button>
        )}
      />
    </div>
  );
}
