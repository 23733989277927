/* eslint-disable @next/next/no-html-link-for-pages */
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as S from "styles/styles";

const UlMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  font-weight: 500;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  .dropdown {
    position: relative;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    list-style-type: none;
    background-color: #d8f4f3;
    top: 0px;
    min-width: 160px;
    padding: 5px 5px;
    margin-top: 18px;
    z-index: 1;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
`;

const LiMenu = styled.li`
  float: left;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  a {
    display: block;
    color: #42a7a2;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
    text-align: center;
    font-weight: 700;
    padding: 0px 16px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
  .dropdown-content-itens {
    text-align: left !important;
  }
  .dropdown-content-itens a {
    padding: 15px 15px !important;
    text-align: left !important;
  }
  .dropdown-content a {
    display: block;
    color: #302f2f;
    text-align: center;
    padding: 5px 5px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
  a:hover {
    color: #42424280;
  }
`;

export default function NavbarFooter(props: any) {
  const [ativo, setAtivo] = useState(false);
  const [ativoReserva, setAtivoReserva] = useState(false);

  function ativaMenu() {
    setAtivo(!ativo);
  }
  function ativaMenuReserva() {
    setAtivoReserva(!ativoReserva);
  }

  return (
    <S.divMenuFixo>
      <S.divMenuFixoInterno className="divmenu">
        <UlMenu>
          {/* <LiMenu className="dropdown primeiroMenu"><a href="#home">Home</a>
                        <ul className="dropdown-content">
                            <li className="dropdown-content-itens"><a href="#">Fale conosco</a></li>
                            <li className="dropdown-content-itens"><a href="#">Galeria de fotos</a></li>
                            <li className="dropdown-content-itens"><a href="#">tour 360</a></li>
                            <li className="dropdown-content-itens"><a href="#">trabalhe conosco</a></li>
                            <li className="dropdown-content-itens"><a href="#">cadastre-se</a></li>
                        </ul>
                    </LiMenu>
                    <LiMenu className="dropdown"><a href="#home">O DPNY</a>
                        <ul className="dropdown-content">
                            <li className="dropdown-content-itens"><a href="#">O hotel</a></li>
                            <li className="dropdown-content-itens"><a href="#">Suites & categorias</a></li>
                            <li className="dropdown-content-itens"><a href="#">gastronomia</a></li>
                            <li className="dropdown-content-itens"><a href="#">jantar romântico</a></li>
                            <li className="dropdown-content-itens"><a href="#">Beauty SPA & Saúde</a></li>
                            <li className="dropdown-content-itens"><a href="#">conceito musical</a></li>
                            <li className="dropdown-content-itens"><a href="#">Prêmios</a></li>
                            <li className="dropdown-content-itens"><a href="#">como chegar</a></li>
                        </ul>
                    </LiMenu> */}
          {/* <LiMenu className="dropdown"><a href="#home">Pacotes</a>
                        <ul className="dropdown-content">
                            <li className="dropdown-content-itens"><a href="#">Janeiro</a></li>
                            <li className="dropdown-content-itens"><a href="#">Fevereiro</a></li>
                            <li className="dropdown-content-itens"><a href="#">MArço</a></li>
                            <li className="dropdown-content-itens"><a href="#">Abril</a></li>
                            <li className="dropdown-content-itens"><a href="#">Maio</a></li>
                            <li className="dropdown-content-itens"><a href="#">Junho</a></li>
                        </ul>
                    </LiMenu> */}
          <LiMenu className="dropdown">
            <a href="/historia-pessoal">HISTÓRIA</a>
          </LiMenu>
          <LiMenu className="dropdown">
            <a href="/como-chegar">COMO CHEGAR</a>
          </LiMenu>
          <LiMenu className="dropdown">
            <a
              href={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/infos/esg.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              PROGRAMA ESG
            </a>
          </LiMenu>
          <LiMenu className="dropdown">
            <a href="/cartas-abertas-dpny">CARTAS ABERTAS</a>
          </LiMenu>
          <LiMenu className="dropdown">
            <a href="/fale-conosco">FALE CONOSCO</a>
          </LiMenu>
          <LiMenu className="dropdown">
            <a href="/trabalhe-conosco">TRABALHE CONOSCO</a>
          </LiMenu>
          <LiMenu className="dropdown">
            <a href="/o-dpny/o-hotel">GALERIA DE FOTOS</a>
          </LiMenu>
        </UlMenu>
      </S.divMenuFixoInterno>
    </S.divMenuFixo>
  );
}
