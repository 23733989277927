/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @next/next/no-img-element */
import { Grid, useMediaQuery } from "@mui/material";
import { GridFooter, InputNewsLatter } from "./style";
import CentralReservas from "./CentralReservas";
import Contato from "./Contato";
import ButtonLaranja from "components/Botoes/ButtonLaranja";
import NavbarFooter from "./NavBarFooter";
import Descricao from "components/TextoSite/Descricao";
import ContainerSite from "components/EstruturaSite/ContainerSite";
import Premios from "./Premios";
import Endereco from "./Endereco";
import Sociais from "./Social";
import NewsLetter from "./NewsLetter";
import NavbarFooterMobile from "./NavBarFooterMobile";
import LAYOUT from "components/Constants";

export default function FooterSite() {
  const larguraTela = useMediaQuery("(min-width:962px)");
  const larguraTelaFooter = useMediaQuery("(min-width:589px)");
  return (
    <>
      <GridFooter
        container
        className="footerSite"
        colors={{ footerPrimary: LAYOUT.colors.tiffany, footerSecundary: "#fff" }}
        justifyContent="center"
      >
        <Grid
          container
          className="desktopMenu"
          width={"100%"}
          sx={{ backgroundColor: "#c5ebe9" }}
          justifyContent="center"
          alignItems={"start"}
        >
          <NavbarFooter />
        </Grid>
        <Grid
          container
          direction={"row"}
          className="rodape"
          alignItems={"start"}
          justifyContent={"start"}
          item
          md={10}
          lg={8}
          xl={6}
          sm={12}
          marginTop={"0px"}
          padding={"10px 25px"}
        >
          <Grid
            container
            item
            md={8}
            lg={8}
            xl={8}
            sm={8}
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Contato />
          </Grid>
          <Grid
            container
            item
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={12}
            justifyContent={"end"}
          >
            <Premios />
          </Grid>
          <Grid
            container
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="newslatterMobile"
          >
            <NewsLetter />
          </Grid>
        </Grid>
      </GridFooter>
    </>
  );
}
