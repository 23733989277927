import { Grid } from "@mui/material";
import { HTMLInputTypeAttribute } from "react";
import InputMask, { ReactInputMask } from "react-input-mask";

interface InputFormikProps {
  value: string;
  name: string;
  placeholder: string;
  type: HTMLInputTypeAttribute;
  mask?: string | null;
  maskChar?: string | null;
  formik?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export default function InputFormik({
  name,
  placeholder,
  value,
  mask = null,
  maskChar = null,
  type = "text",
  formik,
  onChange,
  onBlur,
  onFocus,
}: InputFormikProps) {
  return (
    <Grid container item padding={"0px"} margin={"0px"}>
      {/* @ts-ignore */}
      <InputMask
        name={name}
        placeholder={placeholder}
        type={type}
        style={{
          width: "100%",
          padding: "10px",
          backgroundColor: "#ebeaea",
          borderRadius: "5px",
          margin: "0px",
        }}
        mask={mask == null ? "" : mask}
        maskChar={maskChar}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </Grid>
  );
}
