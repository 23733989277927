import { ReactNode } from "react";

interface ButtonLaranjaProps {
  largura?: string;
  altura?: string;
  link?: boolean;
  url?: string;
  borderRadius?: string;
  onClick?: () => void;
  children: ReactNode;
  type?: "submit" | "button";
}

export default function ButtonCinza({
  largura,
  altura,
  type = "button",
  link = false,
  url = "",
  borderRadius="0px",
  onClick,
  children,
}: ButtonLaranjaProps) {
  if (!link) {
    return (
      <button
        type={type}
        className="btnCinza"
        onClick={onClick}
        style={{ width: largura, height: altura, borderRadius: borderRadius }}
      >
        {children}
      </button>
      // <Button variant="contained" color="secondary" className="btnLaranja" onClick={props.onClick}>Reservar</Button>
    );
  }else{
    return (
        <a
          className="btnLaranja"
          href={url}
          style={{ width: largura, height: altura, textDecoration: "none", textAlign: "center", borderRadius: borderRadius }}
        >
          {children}
        </a>
        // <Button variant="contained" color="secondary" className="btnLaranja" onClick={props.onClick}>Reservar</Button>
      );
  }
}
