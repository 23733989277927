/* eslint-disable @next/next/no-img-element */
import { Grid, useMediaQuery } from "@mui/material";

export default function Premios() {
  const larguraTelaFooter = useMediaQuery("(min-width:589px)");
  //   NEXT_PUBLIC_BUCKET_NAME
  // NEXT_PUBLIC_BUCKET_URL
  return (
    <Grid
      container
      direction="row"
      justifyContent={"start"}
      item
      md={12}
      lg={12}
      xl={12}
      sm={12}
      xs={12}
    >
      <Grid className="premios" item xs={6} sm={12} md={12}>
        <span className="premios-titulo">Premios</span>
        <img
          src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/premios/travellers-choice.svg`}
          alt=""
          width={"40px"}
        />
        <img
          src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/premios/cond-nast.svg`}
          alt=""
          style={{margin: "0px 8px 0px 4px"}}
          width={"40px"}
        />
        <img
          src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/premios/selo-green.svg`}
          alt=""
          width={"40px"}
        />
      </Grid>
      <Grid container className="sociais" item xs={6} sm={12} md={12}>
        <span className="sociais-titulo">Redes Sociais</span>
        <a href="https://www.instagram.com/dpnybeach/" target="_blank" rel="noreferrer" title="Instagram">
          <img
            src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/icones-svg/instagram.svg`}
            alt=""
            width={"40px"}
            height={"40px"}
          />
        </a>
        <a href="https://www.facebook.com/DPNYBEACH" target="_blank" rel="noreferrer" title="Facebook">
          <img
            src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/icones-svg/facebook.svg`}
            alt=""
            width={"40px"}
            height={"40px"}
          />
        </a>
        <a href="https://www.youtube.com/@DPNYBEACH" target="_blank" rel="noreferrer" title="Youtube">
          <img
            src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/icones-svg/youtube.svg`}
            alt=""
            width={"40px"}
            height={"40px"}
          />
        </a>
        <a href="https://x.com/DPNYBEACH" target="_blank" rel="noreferrer" title="rede social x">
          <img
            src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/icones-svg/xtwitter.svg`}
            alt=""
            width={"40px"}
            height={"40px"}
          />
        </a>
      </Grid>
    </Grid>
  );
}
