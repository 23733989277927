import styled from "styled-components";

export const DivMenuFixo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  max-width: 1200px;
  height: 70px;
  /* background-color: #61d8d2; */
`;

export const LogoMenu = styled.div`
  display: flex;
  padding: 10px;
  margin-right: 30px;
  margin-top: 8px;
`;

export const DivMenuFixoInterno = styled.div`
  display: flex;
  height: 70px;
  text-align: left;
  margin-right: 10px;
  transition: all ease 0.3s;
  justify-content: center;
  align-items: center;
`;