import { Grid } from "@mui/material";
import { InputNewsLatter } from "../style";
import ButtonLaranja from "components/Botoes/ButtonLaranja";
import Descricao from "components/TextoSite/Descricao";
import TituloDourado from "components/TextoSite/TituloDourado";
import Titulo from "components/TextoSite/Titulo";
import * as yup from "yup";
import { useFormik } from "formik";
import FormikForm from "components/FormikForm";
import axios from "axios";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import LAYOUT from "components/Constants";
import ButtonCinza from "components/Botoes/ButtonCinza";

const validationSchema = yup.object({
  email: yup.string().email("Email invalido!").required("Obrigatorio!"),
});

interface FormValues {
  email: string;
}

export default function NewsLetter() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSucessoOpen, setIsModalSucessoOpen] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const handleCaptchaSuccess = (
    token: string | null,
    submitForm: () => void
  ) => {
    setCaptchaToken(token);
    if (token) {
      submitForm(); // Envia o formulário
    }
    setIsModalOpen(false); // Fecha o Modal
  };
  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const enviaForm = await axios.post("/api/newslatter", values);
      setIsModalSucessoOpen(true);
      resetForm();
    },
  });
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={10}
      justifyContent="center"
      alignItems="center"
    >
      <FormikForm formik={formik}>
        <Grid
          container
          item
          md={12}
          lg={12}
          xl={12}
          sm={12}
          justifyContent="space-between"
          alignItems="start"
          marginTop="20px"
        >
          <Grid container item md={12} lg={12} xl={8} sm={12}>
            <Descricao>Receba as melhores ofertas DPNY</Descricao>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            item
            md={8}
            lg={8}
            xl={8}
            sm={8}
            xs={7}
            marginTop={"10px"}
            paddingRight={"5px"}
          >
            <InputNewsLatter
              type="text"
              placeholder="E-mail"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
              <div
                style={{
                  paddingLeft: "10px",
                  color: "#f96666",
                  fontFamily: "sans-serif",
                  fontSize: "10px",
                  textAlign: "left",
                }}
              >
                {formik.errors.email}
              </div>
            )}
          </Grid>
          <Grid
            container
            item
            md={4}
            lg={4}
            xl={4}
            sm={4}
            xs={5}
            paddingLeft={"5px"}
            marginTop={"10px"}
          >
            <ButtonLaranja
              type="button"
              borderRadius="10px"
              largura="110px"
              altura="45px"
              onClick={() => setIsModalOpen(true)}
            >
              <Descricao sx={{ fontSize: "16px" }}>Cadastrar</Descricao>
            </ButtonLaranja>
          </Grid>
        </Grid>
        {isModalOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
              }}
            >
              <Descricao
                sx={{
                  padding: "0px",
                  margin: "0px",
                  color: LAYOUT.colors.cinza,
                }}
              >
                Confirme que você não é um robô:
              </Descricao>
              <ReCAPTCHA
                sitekey={`${process.env.NEXT_PUBLIC_KEY_RECAPTCHA}`}
                onChange={(token) =>
                  handleCaptchaSuccess(token, formik.submitForm)
                }
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <ButtonCinza onClick={() => setIsModalOpen(false)}>
                  Cancelar
                </ButtonCinza>
              </div>
            </div>
          </div>
        )}
        {isModalSucessoOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
              }}
            >
              <Descricao
                sx={{
                  padding: "0px",
                  margin: "0px",
                  color: LAYOUT.colors.cinza,
                }}
              >
                Cadastro realizado com sucesso!
              </Descricao>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <ButtonLaranja onClick={() => setIsModalSucessoOpen(false)}>
                  Ok
                </ButtonLaranja>
              </div>
            </div>
          </div>
        )}
      </FormikForm>
    </Grid>
  );
}
