/* eslint-disable @next/next/no-img-element */

interface PropsLogoSite {
  tipo?: string;
  tamanho?: string;
}
export default function LogoTopoSite(props: PropsLogoSite) {
  if (props.tipo == "preto") {
    return (
      <>
        <img
          src="/svg/logo-dpny-preto.svg"
          alt="Logotipo do Hotel DPNY"
          width={props.tamanho}
        />
      </>
    );
  } else {
    return (
      <>
        <img
          src="/svg/dpny-logo.svg"
          alt="Logotipo do Hotel DPNY"
          width={props.tamanho}
        />
      </>
    );
  }
}
