/* eslint-disable @next/next/no-html-link-for-pages */
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import Navbar from "../NavBar";
import MenuDrawer from "../MenuDrawer/MenuDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import LogoTopoSite from "components/LogoTopoSite";
import Descricao from "components/TextoSite/Descricao";
import LAYOUT from "components/Constants";

export default function TopoSite() {
  const [ativo, setAtivo] = useState(false);
  const larguraTela = useMediaQuery("(min-width:962px)");

  function ativaMenuDrawer() {
    setAtivo(!ativo);
  }
  return (
    <>
      <Grid
        container
        direction={"row"}
        className="desktopMenu"
        justifyContent="space-around"
        alignItems={"center"}
        padding={0}
        position="fixed"
        width="100%"
        sx={{ backgroundColor: "#00c4b8", zIndex: "2" }}
        height={"70px"}
      >
        <Navbar />
      </Grid>
      <Grid
        container
        item
        md={12}
        sm={12}
        direction={"row"}
        className="mobileMenu"
        justifyContent="center"
        alignItems={"center"}
        padding={"0 20px"}
        position="fixed"
        width="100%"
        sx={{ backgroundColor: "#00c4b8", zIndex: "5" }}
        height={"70px"}
      >
        <MenuDrawer open={ativo} ativar={ativaMenuDrawer} />
        <Grid
          container
          item
          sm={12}
          xs={12}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          height={"40px"}
          flexWrap={"nowrap"}
        >
          <Grid
            container
            item
            direction={"row"}
            width="auto"
            alignItems={"center"}
          >
            <Grid>
              <IconButton aria-label="Menu Site" onClick={ativaMenuDrawer} className="teste">
                <MenuIcon
                  sx={{ color: "#fff", fontSize: 30, padding: "3px" }}
                />
              </IconButton>
            </Grid>
            <Grid>
              <Descricao
                sx={{
                  fontSize: "15px",
                  padding: "0px",
                  margin: "0px",
                  color: "#fff",
                }}
              >
                MENU
              </Descricao>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"column"}
            width={"auto"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <a href="/" style={{ display: "flex", justifyContent: "center" }}>
              <LogoTopoSite tamanho={"60px"} />
            </a>
          </Grid>

          <Grid
            container
            item
            sm={3}
            xs={3}
            direction={"row"}
            alignItems={"center"}
          >
            <Grid>
              <Typography sx={{ fontSize: 12, color: "#fff" }}>
                {/* LANGUAGE */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
