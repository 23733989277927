/* eslint-disable @next/next/no-html-link-for-pages */
import { Button, Container, Divider, Grid } from "@mui/material";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { CloseMenuIcon, MenuIcon } from "Icons/menu";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as S from "styles/styles";
import LogoTopoSite from "../LogoTopoSite";
import { DivMenuFixo, DivMenuFixoInterno, LogoMenu } from "./styles";
import { getMenuSite } from "../../utils/listaMenu/getMenuSite";
import { useSiteContexto } from "context/context";
import LAYOUT from "components/Constants";

const UlMenu = styled.ul`
  display: contents;
  align-items: center;
  list-style-type: none;
  font-weight: 500;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  .dropdown {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    list-style-type: none;
    background-color: #61d8d2eb;
    top: 27px;
    left: 0;
    min-width: 250px;
    padding: 5px 5px;
    margin-top: 43px;
    z-index: 1;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
`;

const LiMenu = styled.li`
  float: left;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  /* ::after{
    transform: rotate(45deg);
    background-color: #fcfafa;
    content: " ";
    display: flex;
    position: absolute;
    height: 0.5rem;
    width: 0.5rem;
    margin-top: -15px;
} */

  a {
    display: block;
    color: #fff;
    text-align: center;
    font-family: ${LAYOUT.fonts.family.titulo};
    padding: 0px 16px;
    font-weight: 700;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
  }
  .dropdown-content-itens {
    text-align: left !important;
  }
  .dropdown-content-itens a {
    padding: 10px 15px !important;
    text-align: left !important;
    font-size: 12px !important;
    color: #fff !important;
    font-family: ${LAYOUT.fonts.family.titulo};
  }
  .dropdown-content a {
    display: block;
    color: #302f2f;
    text-align: center;
    padding: 5px 5px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
  a:hover {
    color: #42424280;
  }
`;
// teste
export default function Navbar(props: any) {
  const [ativo, setAtivo] = useState(false);
  const [ativoReserva, setAtivoReserva] = useState(false);
  const [menuPacotes, setMenuPacotes] = useState([]);

  const { menu } = useSiteContexto();

  function ativaMenu() {
    setAtivo(!ativo);
  }
  function ativaMenuReserva() {
    setAtivoReserva(!ativoReserva);
  }

  return (
    <DivMenuFixo>
      <LogoMenu>
        <a href="/">
          <LogoTopoSite tamanho={"60px"} />
        </a>
      </LogoMenu>
      <DivMenuFixoInterno className="divmenu">
        <UlMenu>
          {/* <LiMenu className="dropdown primeiroMenu"><a href="#home">Home</a>
                        <ul className="dropdown-content">
                            <li className="dropdown-content-itens"><a href="#">Fale conosco</a></li>
                            <li className="dropdown-content-itens"><a href="#">Galeria de fotos</a></li>
                            <li className="dropdown-content-itens"><a href="#">tour 360</a></li>
                            <li className="dropdown-content-itens"><a href="#">trabalhe conosco</a></li>
                            <li className="dropdown-content-itens"><a href="#">cadastre-se</a></li>
                        </ul>
                    </LiMenu>
                    <LiMenu className="dropdown"><a href="#home">O DPNY</a>
                        <ul className="dropdown-content">
                            <li className="dropdown-content-itens"><a href="#">O hotel</a></li>
                            <li className="dropdown-content-itens"><a href="#">Suites & categorias</a></li>
                            <li className="dropdown-content-itens"><a href="#">gastronomia</a></li>
                            <li className="dropdown-content-itens"><a href="#">jantar romântico</a></li>
                            <li className="dropdown-content-itens"><a href="#">Beauty SPA & Saúde</a></li>
                            <li className="dropdown-content-itens"><a href="#">conceito musical</a></li>
                            <li className="dropdown-content-itens"><a href="#">Prêmios</a></li>
                            <li className="dropdown-content-itens"><a href="#">como chegar</a></li>
                        </ul>
                    </LiMenu> */}

          {/* <LiMenu className="dropdown primeiroMenu"><a href="#home">O HOTEL</a></LiMenu> */}
          <LiMenu className="dropdown">
            <a href="#home">O DPNY</a>
            <ul className="dropdown-content">
              <li className="dropdown-content-itens">
                <a href="/o-dpny/o-hotel">O HOTEL</a>
                <a href="/o-dpny/suites">Suítes & Categorias</a>
                <a href="https://gastronomia.dpny.com.br/">Gastronomia</a>
                <a href="/o-dpny/experiencias-dpny">Experiencias</a>
                <a href="/como-chegar">Como Chegar</a>
                <a href="/day-card">Day Card</a>
                <a href="/tarifas/beauty-spa-saude">Beauty SPA & Saúde</a>
                <a href="/o-dpny/conceito-musical/dpny-records">
                  Conceito Musical & Artes
                </a>
              </li>
            </ul>
          </LiMenu>
          <LiMenu className="dropdown">
            <a href="/pacotes">PACOTES</a>
            {menu.pacotes.length > 0 ? (
              <ul className="dropdown-content">
                {menu.pacotes.map((m: any, index: number) => {
                  if (m.mes_menu == 0) {
                    return (
                      <li key={index} className="dropdown-content-itens">
                        <a href={`/pacotes/${m.url_menu}`}>{m.label_menu}</a>
                      </li>
                    );
                  } else {
                    return (
                      <li key={index} className="dropdown-content-itens">
                        <a href={`/pacotes/${m.ano_menu}/${m.url_menu}`}>
                          {m.label_menu}
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            ) : (
              ""
            )}
          </LiMenu>
          {menu.adicional.length > 0
            ? menu.adicional.map((m: any, index: number) => {
                return (
                  <LiMenu key={index} className="dropdown">
                    <a href={m.link_menu_adicional}>{m.nome_menu_adicional}</a>
                  </LiMenu>
                );
              })
            : ""}
        </UlMenu>
      </DivMenuFixoInterno>
    </DivMenuFixo>
  );
}
