import { Button, Grid, TextField } from "@mui/material";
import LAYOUT from "components/Constants";
import styled from "styled-components";

export const DataCalendario = styled.input`
  border: none;
  color: #fff;
  border-bottom: 1px solid #fff !important;
  height: 40px;
  min-width: 150px;
  text-align: center;
  margin: 0px;
  :active {
    border-bottom: 1px solid #fff;
  }
  :hover {
    border-bottom: 1px solid #fff;
  }
  ::selection {
    border-bottom: 1px solid #fff;
  }
  :focus {
    border: none;
    border-bottom: 1px solid #fff;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;
export const TituloCalendario = styled.span`
  font-size: 8px !important;
  font-weight: 500;
  margin-bottom: 2px;
  font-family: ${LAYOUT.fonts.family.descricao};
  color: #fff;
`;

export const TituloPacotes = styled.h1`
  font-size: 14px !important;
  color: #fff;
`;

export const ButtonPacotes = styled(Button)`
  font-size: 12px !important;
  color: #000;
`;

export const GridMobile = styled(Grid)`
`;

export const Titulo = styled.span`
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
  font-weight: 500;
  color: #fff;
`;
