import { Grid } from "@mui/material";
import { yellow } from "@mui/material/colors";
import {
  FormikConfig,
  FormikHandlers,
  FormikProps,
  FormikSharedConfig,
  WithFormikConfig,
  useFormik,
} from "formik";
interface FormikFormProps {
  formik?: FormikHandlers;
  children: React.ReactNode;
}
export default function FormikForm({ formik, children }: FormikFormProps) {
  return (
    <Grid container>
      {formik ? (
        <form
          onSubmit={formik?.handleSubmit}
          encType="multipart/form-data"
          style={{ width: "100%" }}
        >
          {children}
        </form>
      ) : (
        <form>{children}</form>
      )}
    </Grid>
  );
}
