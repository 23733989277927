/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable @next/next/no-img-element */
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Collapse,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSiteContexto } from "context/context";
import Titulo from "components/TextoSite/Titulo";
import LAYOUT from "components/Constants";
import TituloDourado from "components/TextoSite/TituloDourado";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import Descricao from "components/TextoSite/Descricao";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MenuDrawer(props: any) {
  const [subMenuOpen, setSubMenuOpen] = useState("");
  const { menu }: { menu: any } = useSiteContexto();
  const list = () => (
    <Box sx={{ width: 300 }}>
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  function handleClick(nome: string) {
    if (subMenuOpen == nome) {
      setSubMenuOpen("");
    } else {
      setSubMenuOpen(nome);
    }
  }
  return (
    <div>
      {/* @ts-ignore */}
      <SwipeableDrawer
        anchor="left"
        open={props.open}
        onClose={props.ativar}
        onOpen={props.ativar}
        className="Menu-lateral"
      >
        <DrawerHeader sx={{ backgroundColor: "#9ce5e3", height: "70px" }}>
          <Grid container direction={"row"} alignItems={"center"}>
            <Grid
              item
              md={11}
              sm={11}
              xs={11}
              justifyContent={"center"}
              textAlign={"center"}
              alignItems={"center"}
            >
              <Descricao sx={{ fontSize: "25px", color: "#fff" }}>
                MENU
              </Descricao>
            </Grid>
            <Grid item md={1} sm={1} xs={1} alignItems={"center"}>
              <IconButton onClick={props.ativar}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <Divider />
        <List
          sx={{ width: 300 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {[
            {
              item: {
                nome: "O DPNY",
                submenu: true,
                open: false,
                type: "menu",
                menu: [
                  { nome: "O Hotel", url: "/o-dpny/o-hotel" },
                  { nome: "Suítes & Categorias", url: "/o-dpny/suites" },
                  {
                    nome: "Gastronomia",
                    url: "https://gastronomia.dpny.com.br/",
                  },
                  { nome: "Experiências", url: "/o-dpny/experiencias-dpny" },
                  { nome: "Como Chegar", url: "/como-chegar" },
                  { nome: "Day Card", url: "/day-card" },
                  {
                    nome: "Beauty Spa & Saúde",
                    url: "/tarifas/beauty-spa-saude",
                  },
                  {
                    nome: "Conceito Musical & Artes",
                    url: "/o-dpny/conceito-musical/dpny-records",
                  },
                ],
              },
            },
            {
              item: {
                nome: "PACOTES",
                submenu: true,
                open: false,
                type: "pacotes",
                pacotes: menu.pacotes,
              },
            },
          ].map((itens, index) => {
            return (
              <div key={index}>
                <ListItemButton onClick={() => handleClick(itens.item.nome)}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"start"}
                    alignItems={"center"}
                  >
                    <Grid item flex={1}>
                      <TituloDourado
                        sx={{
                          fontFamily: LAYOUT.fonts.family.titulo,
                          color: "#fff",
                          fontSize: "18px",
                          textAlign: "left",
                          textDecoration:
                            subMenuOpen == itens.item.nome
                              ? "underline"
                              : "none",
                        }}
                      >
                        {itens.item.nome}
                      </TituloDourado>
                    </Grid>
                    <Grid item>
                      {subMenuOpen == itens.item.nome ? (
                        <IconMinus size={"15px"} />
                      ) : (
                        <IconPlus size={"15px"} />
                      )}
                    </Grid>
                  </Grid>
                </ListItemButton>
                {itens.item.type == "pacotes" ? (
                  <Collapse
                    in={subMenuOpen == itens.item.nome ? true : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {itens.item.pacotes?.map((pacotes: any, id: number) => {
                        if (pacotes.mes_menu == 0) {
                          return (
                            <ListItemButton key={id} sx={{ pl: 4 }}>
                              <a href={`/pacotes/${pacotes.url_menu}`}>
                                <TituloDourado
                                  sx={{
                                    textTransform: "uppercase",
                                    fontFamily: LAYOUT.fonts.family.titulo,
                                    color: "#fff",
                                    fontSize: "14px",
                                  }}
                                >
                                  {pacotes.label_menu}
                                </TituloDourado>
                              </a>
                            </ListItemButton>
                          );
                        } else {
                          return (
                            <ListItemButton key={id} sx={{ pl: 4 }}>
                              <a
                                href={`/pacotes/${pacotes.ano_menu}/${pacotes.url_menu}`}
                              >
                                <TituloDourado
                                  sx={{
                                    textTransform: "uppercase",
                                    fontFamily: LAYOUT.fonts.family.titulo,
                                    color: "#fff",
                                    fontSize: "14px",
                                  }}
                                >
                                  {pacotes.label_menu}
                                </TituloDourado>
                              </a>
                            </ListItemButton>
                          );
                        }
                      })}
                    </List>
                  </Collapse>
                ) : (
                  <Collapse
                    in={subMenuOpen == itens.item.nome ? true : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {itens.item.menu?.map((menu: any, id: number) => {
                        return (
                          <ListItemButton key={id} sx={{ pl: 4 }}>
                            <a href={`${menu.url}`}>
                              <TituloDourado
                                sx={{
                                  textTransform: "uppercase",
                                  fontFamily: LAYOUT.fonts.family.titulo,
                                  color: "#fff",
                                  fontSize: "14px",
                                }}
                              >
                                {menu.nome}
                              </TituloDourado>
                            </a>
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                )}
              </div>
            );
          })}
          {menu.adicional.length > 0
            ? menu.adicional.map((m: any, index: number) => {
                return (
                  <ListItemButton key={index}>
                    <a href={`${m.link_menu_adicional}`}>
                      <TituloDourado
                        sx={{
                          textTransform: "uppercase",
                          fontFamily: LAYOUT.fonts.family.titulo,
                          color: "#fff",
                          fontSize: "18px",
                        }}
                      >
                        {m.nome_menu_adicional}
                      </TituloDourado>
                    </a>
                  </ListItemButton>
                );
              })
            : ""}
        </List>
      </SwipeableDrawer>
    </div>
  );
}
