import { Button } from "@mui/material";
import LAYOUT from "components/Constants";
import { ReactNode } from "react";

interface ButtonDouradoProps {
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  children: ReactNode;
  link?: boolean;
  url?: string;
  type?: "submit" | "button";
  target?: '_blank' | '_self' | '_parent' | '_top';
  onClick?: () => void;
}

export default function ButtonDourado({
  width,
  height,
  margin,
  padding,
  children,
  link = false,
  type="button",
  url = "",
  target='_self',
  onClick,
}: ButtonDouradoProps) {
  if (!link) {
    return (
      <button
        type={type}
        className="btnDourado"
        style={{
          backgroundColor: LAYOUT.colors.dourado,
          width: width,
          height: height,
          margin: margin,
          padding: padding,
          border: "none",
        }}
        onClick={onClick}
      >
        {children}
      </button>
      // <Button variant="contained" color="secondary" className="btnLaranja" onClick={props.onClick}>Reservar</Button>
    );
  } else {
    return (
      <a
        className="btnDourado"
        style={{
          display: "flex",
          backgroundColor: LAYOUT.colors.dourado,
          width: width,
          height: height,
          margin: margin,
          padding: padding,
          border: "none",
          justifyContent: "center",
          alignItems: "center",
        }}
        href={url}
      >
       {children}
      </a>
    );
  }
}
