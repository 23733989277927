import { SxProps, Theme, Typography } from "@mui/material";
import { CSSProperties } from "react";
interface PropsTextoSite {
  sx?: CSSProperties | undefined;
  size?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  margin?: string;
  color?: string;
  padding?: string;
  textAlign?: "center" | "justify" | "left" | "right";
  lineHeight?: string;
  fontWeight?: string;
  className?: string;
  position?: "static" | "relative" | "absolute" | "sticky" | "fixed";
  children: any;
  onClick?: () => void;
}
export default function Descricao({
  size = "12px",
  marginTop = "0px",
  lineHeight = "1.5",
  fontWeight = "400",
  marginLeft = "0px",
  marginRight = "0px",
  margin = "15px 0px 0px 0px",
  textAlign = "justify",
  padding = "0px 20px",
  color = "#848484",
  className,
  position = "relative",
  children,
  sx,
  onClick=() => {},
}: PropsTextoSite) {
  return (
    <span
    style={sx}
    className="textoDescricao"
    onClick={onClick}
    >
      {children}
    </span>
  );
}
