import { Typography } from "@mui/material";
import { CSSProperties } from "react";
interface PropsTextoSite {
  sx?: CSSProperties | undefined;
  size?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  color?: string;
  padding?: string;
  textAlign?: "center" | "left" | undefined;
  fontWeight?: string;
  children: string;
}
export default function TituloDourado({
  size = "24px",
  marginTop = "0px",
  marginLeft = "0px",
  fontWeight = "400",
  marginRight = "0px",
  textAlign = "center",
  padding = "0px 0px",
  marginBottom = "0px",
  color = "#d09e2c",
  children,
  sx,
}: PropsTextoSite) {
  return (
    <p
    style={sx}
    className="textoTituloDourado"
      // style={{
      //   fontSize: size,
      //   color: color,
      //   fontWeight: fontWeight,
      //   textAlign: textAlign,
      //   marginTop: marginTop,
      //   marginBottom: marginBottom,
      //   marginLeft: marginLeft,
      //   marginRight: marginRight,
      //   padding: padding,
      //   textTransform: "uppercase",
      //   fontFamily: "'Cinzel', serif",
      // }}
    >
      {children}
    </p>
  );
}
